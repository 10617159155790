<template>
  <div class="home">
    <div id="app">
      <div id="colorlib-page">
        <div class="container-wrap">
          <a href="#" class="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i></i></a>
          <aside id="colorlib-aside" role="complementary" class="border js-fullheight">
            <nav id="colorlib-main-menu" role="navigation" class="navbar">
              <div class="text-center">
                <h1 id="colorlib-logo"><a href="/">Ari Choi</a></h1>
                <a class="email" href="mailto:ari.choi@outlook.com"><span class="position">ari.choi@outlook.com</span></a>
              </div>
              <div id="navbar" class="collapse">
                <ul>
                  <li class="active"><a href="#" data-nav-section="home">Home</a></li>
                  <li><a href="#" data-nav-section="about">About Me</a></li>
                  <li><a href="#" data-nav-section="skills">Skills</a></li>
                  <li><a href="#" data-nav-section="work">My Work</a></li>
                  <li><a href="#" data-nav-section="contact">Contact</a></li>
                </ul>
              </div>
            </nav>
          </aside>
          <div id="colorlib-main">
            <section id="colorlib-hero" class="js-fullheight" data-section="home">
              <div class="flexslider js-fullheight">
                <ul class="slides">
                  <li style="background-image: url(images/ari04.jpeg);">
                    <div class="overlay"></div>
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                          <div class="slider-text-inner js-fullheight">
                            <div>
                              <h1 class="main-intro">Hello! <br>I'm Ari</h1>
                              <h2 class="main-intro">A Full Stack Developer <br> based in Vancouver, Canada</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  </ul>
                </div>
            </section>

            <section class="colorlib-about" data-section="about">
              <div class="colorlib-narrow-content">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                      <div class="col-md-12">
                        <div class="about-desc">
                          <span class="heading-meta">About Me</span>
                          <h2 class="colorlib-heading">Who Am I?</h2>
                          <img class="my-dev-img" src="images/ari-sticker.png" alt="">
                          <p><strong>👋🏻 Hello I am..</strong> a Full stack Web Developer</p>
                          <p><strong>🧬 My background was...</strong> Medical Laboratory Science</p>
                          <p><strong>🌻 I love...</strong> Coding, Traveling to beautiful places, Being around animals, and Gaming.</p>
                          <p><strong>📝 I am currently learning ...</strong> React.js</p>
                          <p>I am a passionate developer who loves to learn new things and has a strong love for coding!<br> As a team player, I enjoy working with others and would love to quickly join a new project to work together.<br><br><a class="resume" href="https://drive.google.com/file/d/1G8AOWsOBpW61uDZS9iXXXJbQtPijDre-/view?usp=sharing" target='_blank'>Click here to check out my resume!</a> :)</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="colorlib-skills" data-section="skills">
              <div class="colorlib-narrow-content">
                <div class="row">
                  <div class="col-md-12 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                    <span class="heading-meta">My Specialty</span>
                    <h2 class="colorlib-heading animate-box">My Skills</h2>
                    <p>I am someone who finds joy in learning new technologies and is not afraid to dive into them. Keeping up with the latest trends, I am constantly interested in various technologies. I am capable of working both independently and as part of a team.</p>
                  </div>
                </div>
                <div class="row skills animate-box" data-animate-effect="fadeInLeft">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/html5-original-wordmark.svg?size=75&color=currentColor" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/css3-original-wordmark.svg?size=75&color=currentColor" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/sass-original.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/javascript-original.svg?size=75&color=currentColor" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/jquery-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/vuejs-original-wordmark.svg?size=75&color=currentColor" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/react-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/bootstrap-plain-wordmark.svg?size=75&color=8f12ca" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/java-original-wordmark.svg?size=75&color=8f12ca" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://www.vectorlogo.zone/logos/springio/springio-ar21.svg" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/gradle-plain-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/php-original.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/laravel-plain-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/ruby-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/rails-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/npm-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/nodejs-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/oracle-original.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/git-original-wordmark.svg?size=75&color=f01414" alt="">
                  <img class="skill col-xs-3 col-sm-2" src="https://icongr.am/devicon/github-original-wordmark.svg?size=75&color=f01414" alt="">
                </div>
              </div>
            </section>

            <section class="colorlib-work" data-section="work">
              <div class="colorlib-narrow-content">
                <div class="row">
                  <div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                    <span class="heading-meta">My Work</span>
                    <h2 class="colorlib-heading animate-box">Recent Work</h2>
                  </div>
                </div>
                <div class="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div class="col-md-12">
                    <p class="work-menu">
                      <span><a :class="{ 'active': active === 'all'}" v-on:click="showProjects('all')">All</a></span>
                      <span><a :class="{ 'active': active === 'completed' }" v-on:click="showProjects('completed')">Completed</a></span>
                      <span><a :class="{ 'active': active === 'in-progress' }" v-on:click="showProjects('in-progress')">In Progress</a></span>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft" v-show="showAllProjects || showInProgressProjects">
                    <div class="project" style="background-image: url(images/img-kmove.png);">
                      <div class="desc">
                        <div class="con">
                          <h3><a href="https://www.kmovevan.org/" target='_blank'>Kotra K-move Official Web Site</a></h3>
                          <span>PHP, Laravel, Laravel Mix, Bootstrap, SASS, Browser Sync, MySQL</span>
                          <span class="project-link"><a class="project-link-url" href="https://www.kmovevan.org/" target='_blank'>> Go to the page</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 animate-box" data-animate-effect="fadeInRight" v-show="showAllProjects || showInProgressProjects">
                    <div class="project" style="background-image: url(images/img-babyitems.png);">
                      <div class="desc">
                        <div class="con">
                          <h3><a href="https://ourbabyitems.com/" target='_blank'>Our Baby Items</a></h3>
                          <h3 style="font-size: small;"><a href="https://ourbabyitems.com/" target='_blank'>(Parenting Gear Information Website)</a></h3>
                          <span>JavaScript, Vue.js, Bulma, Ruby, Ruby on Rails, PostgreSQL</span>
                          <span class="project-link"><a class="project-link-url" href="https://ourbabyitems.com/" target='_blank'>> Go to the page</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft" v-show="showAllProjects || showInProgressProjects">
                    <div class="project" style="background-image: url(images/img-solemate.png);">
                      <div class="desc">
                        <div class="con">
                          <h3><a href="https://github.com/choiari1002/shop" target='_blank'>Solemate</a></h3>
                          <h3 style="font-size: small;"><a href="https://github.com/choiari1002/shop" target='_blank'>(Online Shoe Shop)</a></h3>
                          <span>JavaScript, React.js, redux, Bootstrap, Node.js, Express</span>
                          <span class="project-link"><a class="project-link-url" href="https://github.com/choiari1002/shop" target='_blank'>> GitHub link</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 animate-box" data-animate-effect="fadeInLeft" v-show="showAllProjects || showCompletedProjects">
                    <div class="project" style="background-image: url(images/img-dscinema.jpg);">
                      <div class="desc">
                        <div class="con">
                          <h3><a href="https://github.com/Ryujonghak/DsProject" target='_blank'>DS CINEMA</a></h3>
                          <span>JavaScript, Vue.js, Node.js, Bootstrap, Vee validate, JAVA, Spring Boot, Spring Security, Lombok, JWT, Gradle, ORACLE database </span>
                          <span class="project-link"><a class="project-link-url" href="https://github.com/Ryujonghak/DsProject" target='_blank'>> GitHub link</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 animate-box" data-animate-effect="fadeInRight" v-show="showAllProjects || showCompletedProjects">
                    <div class="project" style="background-image: url(images/img-huile.png);">
                      <div class="desc">
                        <div class="con">
                          <h3><a href="https://github.com/choiari1002/huile" target='_blank'>Huile</a></h3>
                          <h3 style="font-size: small;"><a href="https://github.com/choiari1002/huile" target='_blank'>(Custom Essential Oil Website)</a></h3>
                          <span>JavaScript, Vue.js, Node.js, Bootstrap, Java, Spring Boot, Spring Security, Lombok, JWT, Gradle, Oracle database</span>
                          <span class="project-link"><a class="project-link-url" href="https://github.com/choiari1002/huile" target='_blank'>> GitHub link</a></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="colorlib-contact" data-section="contact">
              <div class="colorlib-narrow-content">
                <div class="row">
                  <div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                    <span class="heading-meta">Get in Touch</span>
                    <h2 class="colorlib-heading">Contact</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <a href="mailto:ari.choi@outlook.com"><h3 class="animate-box" data-animate-effect="fadeInLeft" style="text-decoration: underline;">ari.choi@outlook.com</h3></a>
                    <div class="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div class="colorlib-icon">
                        <a class="contact-url" href="https://github.com/choiari1002" target='_blank'><i class="icon-social-github"></i></a>
                      </div>
                    </div>
                    <div class="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div class="colorlib-icon">
                        <a class="contact-url" href="https://www.linkedin.com/in/arichoi/" target='_blank'><i class="icon-social-linkedin"></i></a>
                      </div>
                    </div>
                    <div class="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div class="colorlib-icon">
                        <a class="contact-url" href="mailto:ari.choi@outlook.com"><i class="icon-mail"></i></a>
                      </div>
                    </div>
                    <div class="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInLeft">
                      <div class="colorlib-icon">
                        <a class="contact-url" href="https://drive.google.com/file/d/1G8AOWsOBpW61uDZS9iXXXJbQtPijDre-/view?usp=sharing" target='_blank'><i class="icon-note"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import main from "@/assets/js/main.js";

export default {
  name: 'HomeView',
  mounted() {
    main();
  },
  data() {
    return {
      showAllProjects: true,
      showCompletedProjects: false,
      showInProgressProjects: false,
      active: 'all',
    }
  },
  methods: {
  showProjects: function(category) {
    this.active = category;
    if (category === 'all') {
      this.showAllProjects = true;
      this.showCompletedProjects = true;
      this.showInProgressProjects = true;
    } else if (category === 'completed') {
      this.showAllProjects = false;
      this.showCompletedProjects = true;
      this.showInProgressProjects = false;
    } else if (category === 'in-progress') {
      this.showAllProjects = false;
      this.showCompletedProjects = false;
      this.showInProgressProjects = true;
    }
  }
}
}
</script>

<style scoped>
@import "@/assets/css/style.css";
.colorlib-contact {
  margin-bottom: 40%
}
@media screen and (max-width: 768px) {
  .colorlib-contact {
    margin-bottom: 0
  }
}
</style>